import React from "react"
import InnerHeader from "../../components/global/page-partials/inner-header-1/InnerHeader"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import * as strategyStyles from "./strategy.module.scss"

const Strategy = ({ pageContext, location }) => {
  const pageTitle = "Strategy"

  return (
    <Layout>
      <Seo title={pageTitle} />
      <InnerHeader
        pageTitle={pageTitle}
        pageContext={pageContext}
        location={location}
      />
      <div className="inner-container-alt inner-y-padding">
        <h2>Our Strategy</h2>
        <p>Rocky Brands is a leading designer, manufacturer and marketer of premium quality footwear and apparel marketed under a 
          portfolio of well recognized brand names. 
          Our brands have a rich history of representing high quality, comfortable, functional, and durable footwear. 
          Our products are organized around six target markets: outdoor, work, duty, commercial military, military, and western. 
          As part of our strategy of outfitting consumers from head-to-toe, we market complementary branded apparel and accessories 
          that we believe leverage the strength and positioning of each of our brands.​​</p>
          <strong className={strategyStyles.specifics}>Specific elements of our strategy include:</strong>
          <div>
          <ul className={strategyStyles.listStyling}>
          <li>Excite our customers with great product</li>
          <li>
          Increase brand awareness and stimulate demand through
          improved marketing with an emphasis on digital
          </li>
          <li>
          Provide excellent retail support and expand distribution
           with our key brick and mortar and e-tail partners
          </li>
          <li>
          Accelerate expansion of our direct business through 
          investments in technology and personnel{" "}
          </li>
          <li>
          Take advantage of our internal production to capitalize 
          on the growing number of commercial military opportunities 
          and improve the efficiency of our factories
          </li>
          <img className={strategyStyles.logo} src={
                  require("../../assets/images/logos/rocky-brands-logo.png")
                    .default
                }
                alt="Rocky Brands corporate logo"
              />
        </ul>
        </div>
      </div>
    </Layout>
  )
}

export default Strategy
